import React from "react";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { ChakraProvider } from "@chakra-ui/react";

import { HeroStatement } from "./home/hero_statement";
import Packages from "./home/packages";
import { Footer } from "./footer/App";
import { Header } from "./header/App";

const Main = () => {
  return (
    <ChakraProvider>
      <HubspotProvider>
        <Header />
        <HeroStatement />
        <Packages />
        <Footer />
      </HubspotProvider>
    </ChakraProvider>
  );
};

export default Main;
