import {
  Box,
  Center,
  Heading,
  Image,
  ImageProps,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import buildingBlocks from "../../images/undraw/building_blocks.svg";
import launch from "../../images/undraw/launch.svg";
import responsive from "../../images/undraw/responsive.svg";
import support from "../../images/undraw/support.svg";
import prototyping from "../../images/undraw/prototyping.svg";
import server from "../../images/undraw/server.svg";
import security from "../../images/undraw/security.svg";
import programming from "../../images/undraw/programming.svg";
import { COLORS } from "../shared/colors";

const FeatureImage = (props: ImageProps) => (
  <Image
    borderRadius="10px"
    w="full"
    pos="relative"
    zIndex="1"
    h={{ lg: "100%" }}
    objectFit="cover"
    // alt="Screening talent"
    {...props}
  />
);

export const DesktopFeature = () => (
  <Box w={{ base: "0", lg: "560px" }} h={{ base: "0", lg: "560px" }}>
    <FeatureImage src={buildingBlocks} />
  </Box>
);

const Feature = (props) => {
  // left
  if (props.isImageLeft) {
    return (
      <Stack spacing={0} direction={{ base: "column", lg: "row" }}>
        {/* mobile */}
        <Center h={{ base: 256, lg: 0 }} w={{ base: "100%", lg: 0 }}>
          <FeatureImage h="16rem" w="20rem" src={props.src} />
        </Center>
        <Center h={{ base: "12rem", lg: 0 }} w={{ base: "100%", lg: 0 }}>
          <Heading
            visibility={{ base: "visible", lg: "hidden" }}
            textAlign={"center"}
            // mt="2rem"
            size="xl"
            w="720px"
          >
            {props.text}
          </Heading>
        </Center>
        {/* desktop */}
        <Center h={{ base: 0, lg: 512 }} w={{ base: 0, lg: "100%" }}>
          <FeatureImage h="72%" w="72%" src={props.src} />
        </Center>
        <Center h={{ base: 0, lg: 512 }} w={{ base: 0, lg: "100%" }}>
          <Heading
            visibility={{ base: "hidden", lg: "visible" }}
            size="xl"
            w="76%"
          >
            {props.text}
          </Heading>
        </Center>
      </Stack>
    );
  }
  // right
  return (
    <Stack spacing={0} direction={{ base: "column", lg: "row" }}>
      {/* mobile */}
      <Center h={{ base: 256, lg: 0 }} w={{ base: "100%", lg: 0 }}>
        <FeatureImage h="16rem" w="20rem" src={props.src} />
      </Center>
      <Center h={{ base: "12rem", lg: 0 }} w={{ base: "100%", lg: 0 }}>
        <Heading
          w="76%"
          size="xl"
          textAlign={"center"}
          visibility={{ base: "visible", lg: "hidden" }}
          mt="2rem"
          w="76%"
        >
          {props.text}
        </Heading>
      </Center>
      {/* desktop */}
      <Center h={{ base: 0, lg: 512 }} w={{ base: 0, lg: "100%" }}>
        <Heading
          w="720px"
          size="xl"
          visibility={{ base: "hidden", lg: "visible" }}
          mt="2rem"
          w="76%"
        >
          {props.text}
        </Heading>
      </Center>
      <Center h={{ base: 0, lg: 512 }} w={{ base: 0, lg: "100%" }}>
        <FeatureImage h="72%" w="72%" src={props.src} />
      </Center>
    </Stack>
  );
};

export const Features = (props) => {
  return (
    <VStack ref={props.ref} spacing={{ base: 0, lg: 8 }}>
      <Heading
        mb={{ base: 16, lg: 8 }}
        mt={{ base: 8, lg: 24 }}
        as="h2"
        size="2xl"
        color={COLORS.darkBlue}
        fontWeight="bold"
      >
        Our Process
      </Heading>
      <Feature isImageLeft={true} src={prototyping} text="Design and Scoping" />
      <Feature isImageLeft={false} src={programming} text="Development" />
      <Feature isImageLeft={true} src={responsive} text="Testing and QA" />
      <Feature isImageLeft={false} src={launch} text="Launch" />
      <Feature isImageLeft={true} src={support} text="Maintenance" />
    </VStack>
  );
};
