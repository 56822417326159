import { Box, Text, TextProps } from "@chakra-ui/react";
import * as React from "react";

export const Copyright = (props: TextProps) => (
  <Box>
    <Text mt="4" fontSize="sm" {...props}>
      &copy; {new Date().getFullYear()} Winning With Chase LLC.
    </Text>
    <Text fontSize="sm" {...props}>
      All rights reserved.
    </Text>
  </Box>
);
