import {
  Box,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { HeroButton } from "../shared/buttons";
import { COLORS } from "../shared/colors";
import { DesktopFeature } from "./features";

export function HeroStatement() {
  return (
    <Box as="section" bg={mode("gray.50", "gray.800")} pt="16" pb="24">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "3rem", lg: "2rem" }}
          mt="8"
          align={{ lg: "center" }}
          justify="space-between"
        >
          <Box flex="1" maxW={{ lg: "520px" }}>
            <Heading
              as="h1"
              size="3xl"
              color={COLORS.darkBlue}
              mt="8"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Product Fast 🚀 Service First 🤝
            </Heading>
            <Text
              color={mode("gray.600", "gray.400")}
              mt="4"
              fontSize="lg"
              fontWeight="medium"
            >
              We're a high-performing team with a passion for building
              high-quality products. Let's build something awesome together.
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing="4" mt="8">
              <HeroButton>Get Started</HeroButton>
            </Stack>
          </Box>
          <DesktopFeature />
        </Stack>
      </Box>
    </Box>
  );
}
