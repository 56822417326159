import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { MdWeb, MdSmartphone, MdApi } from "react-icons/md";
import { ActionButton } from "../shared/buttons";
import { PricingCard } from "./../shared/pricing_card";
import { useDisclosure } from "@chakra-ui/hooks";
import { COLORS } from "../shared/colors";

const Packages: React.FC = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        as="section"
        bg={useColorModeValue("gray.50", "gray.800")}
        py="14"
        px={{ base: "4", md: "8" }}
      >
        <Center>
          <Heading as="h2" size="3xl" color={COLORS.darkBlue}>
            Packages
          </Heading>
        </Center>
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: "8", lg: "0" }}
          maxW="7xl"
          mt={{ base: "2rem", lg: "4rem" }}
          mx="auto"
          justifyItems="center"
          alignItems="center"
        >
          <PricingCard
            data={{
              price: "$25K",
              name: "Web",
              features: [
                "Web Application",
                "API & Cloud Infrastructure",
                "Users, Payments, & Integrations",
                "3 months of maintenance",
              ],
            }}
            icon={MdWeb}
            button={
              <ActionButton variant="outline" borderWidth="2px">
                Get started
              </ActionButton>
            }
          />
          <PricingCard
            zIndex={1}
            isPopular
            transform={{ lg: "scale(1.05)" }}
            data={{
              price: "$50K",
              name: "Mobile",
              features: [
                "All Web offerings",
                "iOS Application",
                "Android Application",
                "6 months of maintenance",
              ],
            }}
            icon={MdSmartphone}
            button={<ActionButton>Get Started</ActionButton>}
          />
          <PricingCard
            data={{
              price: "$75K+",
              name: "Complex",
              features: [
                "All Web offerings",
                "All Mobile offerings",
                "Novel functionality",
                "12 months of maintenance",
              ],
            }}
            icon={MdApi}
            button={
              <ActionButton variant="outline" borderWidth="2px">
                Get Started
              </ActionButton>
            }
          />
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Packages;
