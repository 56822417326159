import { Button, ButtonProps } from "@chakra-ui/react";
import * as React from "react";
import { COLORS } from "./colors";

export const LinkedButton = (props: ButtonProps) => (
  <Button
    as="a"
    target="_blank"
    href="https://share.hsforms.com/1Dj9OZNZOQaKlGi3zXcTmvQcozu9"
    {...props}
  />
);

export const ActionButton = (props: ButtonProps) => (
  <LinkedButton
    backgroundColor={COLORS.mediumBlue}
    color="white"
    size="lg"
    w="full"
    fontWeight="extrabold"
    py={{ md: "8" }}
    {...props}
  />
);

export const HeroButton = (props: ButtonProps) => (
  <LinkedButton
    size="lg"
    minW="210px"
    backgroundColor={COLORS.mediumBlue}
    color="white"
    height="14"
    px="8"
    {...props}
  />
);
