import {
  Box,
  BoxProps,
  Button,
  Center,
  HStack,
  Stack,
  StackDivider,
  StackProps,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineMenu, HiX } from "react-icons/hi";
import { LinkedButton } from "../shared/buttons";
import { COLORS } from "../shared/colors";
import { NavLink } from "./NavLink";
import { NavList } from "./NavList";
import { NavListItem } from "./NavListItem";

function scrollTo(y: number) {
  if (typeof window !== "undefined") {
    window.scrollTo(0, y);
  }
}

const MobileNavContent = (props: BoxProps) => {
  const links = [
    // {
    //   label: "Our Process",
    //   onClick: () => {
    //     scrollTo(600);
    //   },
    // },
    {
      label: "Packages",
      onClick: () => {
        scrollTo(672);
      },
    },
  ];

  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box {...props}>
      <Center
        as="button"
        p="2"
        fontSize="2xl"
        color={useColorModeValue("gray.600", "gray.400")}
        onClick={onToggle}
      >
        {isOpen ? <HiX /> : <HiOutlineMenu />}
      </Center>
      <NavList
        pos="absolute"
        insetX="0"
        bg={COLORS.mediumBlue}
        top="64px"
        animate={isOpen ? "enter" : "exit"}
      >
        <Stack
          spacing="0"
          divider={<StackDivider borderColor="whiteAlpha.200" />}
        >
          {links.map((link, index) => (
            <NavListItem key={index}>
              <NavLink.Mobile onClick={link.onClick}>
                {link.label}
              </NavLink.Mobile>
            </NavListItem>
          ))}
        </Stack>
      </NavList>
    </Box>
  );
};

const DesktopNavContent = (props: StackProps) => {
  const links = [
    // {
    //   label: "Our Process",
    //   onClick: () => {
    //     scrollTo(800);
    //   },
    // },
    {
      label: "Packages",
      onClick: () => {
        scrollTo(872);
      },
    },
  ];

  return (
    <HStack spacing="8" align="stretch" {...props}>
      {links.map((link, index) => (
        <NavLink.Desktop key={index} onClick={link.onClick}>
          {link.label}
        </NavLink.Desktop>
      ))}
      <LinkedButton
        height="16"
        rounded="0"
        color="white"
        backgroundColor={COLORS.mediumBlue}
        minW="10rem"
      >
        Get started
      </LinkedButton>
    </HStack>
  );
};

export const NavContent = {
  Mobile: MobileNavContent,
  Desktop: DesktopNavContent,
};
