import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import * as React from "react";
import { NavContent } from "./NavContent";
import wwcBlock from "../../images/wwcBlock.svg";
import { COLORS } from "../shared/colors";

export const Header = () => (
  <Box minHeight="64px">
    <Box
      as="header"
      height={{ base: "16", lg: "16" }}
      bg={useColorModeValue("white", "gray.800")}
      position="relative"
    >
      <Box
        height="100%"
        maxW="7xl"
        mx="auto"
        ps={{ base: "6", md: "8" }}
        pe={{ base: "5", md: "0" }}
      >
        <Flex
          as="nav"
          aria-label="Site navigation"
          align="center"
          justify="space-between"
          height="100%"
        >
          <Box as="a" href="#" rel="home">
            <VisuallyHidden>Winning With Chase</VisuallyHidden>
            <HStack>
              <Image h={12} w={12} src={wwcBlock} />
              <Text color={COLORS.darkBlueGray} fontWeight="bold">
                Winning With Chase
              </Text>
            </HStack>
          </Box>
          <NavContent.Desktop display={{ base: "none", md: "flex" }} />
          <NavContent.Mobile display={{ base: "flex", md: "none" }} />
        </Flex>
      </Box>
    </Box>
  </Box>
);
